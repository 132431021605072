import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import Button from "components/Button"
import WallpaperTile from "components/WallpaperTile"

import { useFavoriteProducts } from "context/favorites"
import { useLocale } from "context/locale"

import fadeAnimation from "utils/fadeAnimation"

const AnimatedWallpaperTile = motion(WallpaperTile)

const Content = ({ pageData }) => {
  const { t } = useLocale()
  const { favorite_products } = useFavoriteProducts()

  const data = [
    {
      label: t("Skonfiguruj tapetę"),
      url: t("/konfigurator/"),
    },
    {
      label: t("Zamów próbki"),
      url: t("/probki/"),
    },
    {
      label: t("Sprawdź tekstury"),
      url: t("/tekstury/"),
    },
  ]

  return (
    <section className="favorites-content">
      <div className="container-fluid">
        <div className="row">
          <motion.div className="col-xl-8 offset-xl-1" {...fadeAnimation}>
            <div
              className="favorites-content__description"
              dangerouslySetInnerHTML={{ __html: pageData?.content }}
            />

            <div className="favorites-content__cta">
              {data?.map((item, index) => (
                <Button className="button--bordered" to={item.url} key={index}>
                  {item.label}
                </Button>
              ))}
            </div>
          </motion.div>
        </div>

        <div className="row">
          {favorite_products?.length > 0 ? (
            favorite_products?.map((item, index) => (
              <div className="col-md-4" key={index}>
                <AnimatedWallpaperTile item={item} {...fadeAnimation} />
              </div>
            ))
          ) : (
            <motion.p className="favorites-content__info" {...fadeAnimation}>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("Brak <strong>produktów</strong> w ulubionych."),
                }}
              />
            </motion.p>
          )}
        </div>
      </div>
    </section>
  )
}

export default Content
