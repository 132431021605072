import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import Newsletter from "components/Newsletter"

import { Content } from "page_components/favorites"

const Favorites = ({ data, location }) => {
  const pageData = data?.allWpPage?.nodes[0]

  return (
    <Layout location={location}>
      <Breadcrumbs title="Ulubione" />
      <PageHeader title="Ulubione" />
      <Content pageData={pageData} />
      <Newsletter />
    </Layout>
  )
}

export const Head = () => <Seo title="Ulubione" />

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDo3NTU2" } }) {
      nodes {
        content
      }
    }
  }
`

export default Favorites
